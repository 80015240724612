const districtColors = [
  { district: "Шевченківський", color: "green" },
  { district: "Франківський", color: "blue" },
  { district: "Личаківський", color: "orange" },
  { district: "Залізничний", color: "lilac" },
  { district: "Сихівський", color: "yellow" },
  { district: "Галицький", color: "red" },
  { district: "Інший", color: "black" },
];

export default districtColors;
